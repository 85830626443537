// src/RegisterPage.js
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { React, useState } from "react";
import NavigationBar from "./NavigationBar";
import { redirectToCheckout } from "./PaymentUtils";
import { auth, db } from "./firebase-config";

const RegisterPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [userId, setUserId] = useState(null);

  const isEmailValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isPasswordStrong = (password) => password.length >= 8;

  const register = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!isEmailValid(email)) {
      setError("Please enter a valid email address.");
      setIsLoading(false);
      return;
    }

    if (!isPasswordStrong(password)) {
      setError("Password must be at least 8 characters long.");
      setIsLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      setIsLoading(false);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await setDoc(
        doc(db, "users", userCredential.user.uid),
        { paid: false },
        { merge: true }
      );
      setUserId(userCredential.user.uid);
      setShowPaymentModal(true);
    } catch (error) {
      console.error("Error during registration", error);
      if (error.code === "auth/email-already-in-use") {
        setError(
          <span style={{ color: "darkred" }}>
            This email is already in use.
          </span>
        );
      } else {
        setError(
          <span style={{ color: "red" }}>
            Failed to register. Please try again.
          </span>
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <NavigationBar isAuthenticated={false} />
      <Flex
        align="center"
        justify="center"
        py={12}
        bgImage="url('/background-space.png')"
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        minHeight="100vh"
      >
        <Container
          maxW="container.sm"
          bg="background.secondary"
          p={8}
          borderRadius="lg"
          boxShadow="lg"
        >
          <VStack spacing={6}>
            <Heading as="h1" size="xl" textAlign="center">
              Register
            </Heading>
            {error && (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            )}
            <Box as="form" w="full" onSubmit={register}>
              <Stack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Confirm Password</FormLabel>
                  <Input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </FormControl>
                <Button
                  isLoading={isLoading}
                  loadingText="Registering..."
                  colorScheme="blue"
                  type="submit"
                  w="full"
                >
                  Register
                </Button>
              </Stack>
            </Box>
            <Box p={0} borderRadius="md" mb={0}>
              <Text fontSize="md" textAlign="center" color="gray.600">
                After registration, you will be redirected to complete payment.
              </Text>
            </Box>
          </VStack>
        </Container>
      </Flex>
      <Modal
        isOpen={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="gray.600">Complete Payment</ModalHeader>
          <ModalCloseButton />
          <ModalBody color="gray.600">
            <p>Please complete the payment to activate your account.</p>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={() =>
                redirectToCheckout(email, userId, setError, setIsLoading)
              }
            >
              Proceed to Payment
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default RegisterPage;
