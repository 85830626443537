//src/DemographicsForm.js
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Select,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, updateDoc } from "firebase/firestore";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const auth = getAuth();
const db = getFirestore();

function DemographicsForm() {
  const navigate = useNavigate();
  const toast = useToast();
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [education, setEducation] = useState("");
  const [occupation, setOccupation] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [ethnicityRace, setEthnicityRace] = useState("");
  const [incomeLevel, setIncomeLevel] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [discordUsername, setDiscordUsername] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const user = auth.currentUser;
    if (!user) {
      setSubmissionStatus("You must be logged in to submit.");
      toast({
        title: "Error",
        description: "You must be logged in to submit.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const userDocRef = doc(db, "users", user.uid);
    try {
      await updateDoc(userDocRef, {
        "demographics.age": age,
        "demographics.gender": gender,
        "demographics.education": education,
        "demographics.occupation": occupation,
        "demographics.maritalStatus": maritalStatus,
        "demographics.ethnicityRace": ethnicityRace,
        "demographics.incomeLevel": incomeLevel,
        "demographics.discordUsername": discordUsername,
      });
      setSubmissionStatus("Submitted successfully!");
      toast({
        title: "Success",
        description: "Form submitted successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/assessment_selection");
    } catch (error) {
      console.error("Error submitting form:", error.message);
      setSubmissionStatus("Submission failed. Please try again.");
      toast({
        title: "Error",
        description: "Error submitting form: " + error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.md" py={5}>
      <VStack spacing={4} as="form" onSubmit={handleSubmit}>
        <Heading as="h1" size="xl" textAlign="center">
          Demographics Information
        </Heading>

        <FormControl isRequired>
          <FormLabel htmlFor="age">Age:</FormLabel>
          <Select
            id="age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            sx={{
              color: "black",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              textShadow: "1px 1px 2px grey",
            }}
          >
            <option value="">Select Age Range</option>
            <option value="under18">Under 18</option>
            <option value="18to24">18-24</option>
            <option value="25to34">25-34</option>
            <option value="35to44">35-44</option>
            <option value="45to54">45-54</option>
            <option value="55to64">55-64</option>
            <option value="65to74">65-74</option>
            <option value="75plus">75+</option>
          </Select>
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="gender">Gender:</FormLabel>
          <Select
            id="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            sx={{
              color: "black",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              textShadow: "1px 1px 2px grey",
            }}
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
            <option value="preferNotToSay">Prefer not to say</option>
          </Select>
        </FormControl>

        {/* Education Field */}
        <FormControl isRequired>
          <FormLabel htmlFor="education">Education:</FormLabel>
          <Select
            id="education"
            value={education}
            onChange={(e) => setEducation(e.target.value)}
            sx={{
              color: "black",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              textShadow: "1px 1px 2px grey",
            }}
          >
            <option value="">Select Education Level</option>
            <option value="none">None</option>
            <option value="highSchool">High School</option>
            <option value="bachelor">Bachelor's Degree</option>
            <option value="master">Master's Degree</option>
            <option value="phd">PhD or higher</option>
          </Select>
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="occupation">Occupation:</FormLabel>
          <Input
            id="occupation"
            type="text"
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
            placeholder="Enter your occupation"
            sx={{
              color: "black",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              textShadow: "1px 1px 2px grey",
              "::placeholder": {
                color: "black",
              },
            }}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="maritalStatus">Marital Status:</FormLabel>
          <Select
            id="maritalStatus"
            value={maritalStatus}
            onChange={(e) => setMaritalStatus(e.target.value)}
            sx={{
              color: "black",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              textShadow: "1px 1px 2px grey",
            }}
          >
            <option value="">Select Marital Status</option>
            <option value="single">Single</option>
            <option value="dating">Dating</option>
            <option value="committed_partnership">
              Committed Partnership (Engaged, Domestic Partnership, Non-Marital
              Life Partnership)
            </option>
            <option value="married">Married</option>
            <option value="open_polyamorous_relationship">
              Open or Polyamorous Relationship
            </option>
            <option value="divorced">Divorced</option>
            <option value="widowed">Widowed</option>
            <option value="its_complicated_other">
              It's Complicated/Other
            </option>
          </Select>
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="ethnicityRace">Ethnicity/Race:</FormLabel>
          <Select
            id="ethnicityRace"
            value={ethnicityRace}
            onChange={(e) => setEthnicityRace(e.target.value)}
            sx={{
              color: "black",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              textShadow: "1px 1px 2px grey",
            }}
          >
            <option value="">Select Ethnicity/Race</option>
            <option value="asian">Asian</option>
            <option value="blackAfricanAmerican">Black/African American</option>
            <option value="hispanicLatino">Hispanic/Latino</option>
            <option value="whiteCaucasian">White/Caucasian</option>
            <option value="nativeAmerican">Native American</option>
            <option value="pacificIslander">Pacific Islander</option>
            <option value="other">Other</option>
            <option value="preferNotToSay">Prefer not to say</option>
          </Select>
        </FormControl>

        <FormControl isRequired>
          <FormLabel htmlFor="incomeLevel">Income ($USD/year):</FormLabel>
          <Select
            id="incomeLevel"
            value={incomeLevel}
            onChange={(e) => setIncomeLevel(e.target.value)}
            sx={{
              color: "black",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              textShadow: "1px 1px 2px grey",
            }}
          >
            <option value="">Select Income Level</option>
            <option value="below20k">Below $20,000</option>
            <option value="20kTo40k">$20,000 to $40,000</option>
            <option value="40kTo60k">$40,000 to $60,000</option>
            <option value="60kTo80k">$60,000 to $80,000</option>
            <option value="80kTo100k">$80,000 to $100,000</option>
            <option value="100kTo200k">$100,000 to $200,000</option>
            <option value="above200k">Above $200,000</option>
            <option value="preferNotToSay">Prefer not to say</option>
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="discordUsername">
            Discord Username (optional, to be added to a group chat with others
            of the same level):
          </FormLabel>
          <Input
            id="discordUsername"
            type="text"
            value={discordUsername}
            onChange={(e) => setDiscordUsername(e.target.value)}
            placeholder="Enter your Discord username (optional)"
            sx={{
              color: "black",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              textShadow: "1px 1px 2px grey",
              "::placeholder": {
                color: "black",
              },
            }}
          />
        </FormControl>

        <Text mt={4} textAlign="center">
          Join the
          <Link
            href="https://discord.gg/6aqpJXawnY"
            isExternal
            color="blue.500"
            ml={1}
          >
            Discord server
          </Link>{" "}
          to connect with others of the same level!
        </Text>

        <Button colorScheme="blue" type="submit">
          Submit
        </Button>

        {submissionStatus && (
          <Text mt={3} textAlign="center">
            {submissionStatus}
          </Text>
        )}
      </VStack>
    </Container>
  );
}

export default DemographicsForm;
