// TermsOfServicePage.js
import { Box, Container, Heading, Link, Text } from "@chakra-ui/react";
import React from "react";
import Footer from "./Footer";
import NavigationBar from "./NavigationBar";

const TermsOfServicePage = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <NavigationBar />
      <Container maxW="container.md" flex="1">
        <Heading as="h1" mb={4}>
          Terms of Service
        </Heading>

        <Text mb={4}>Effective Date: {currentYear}</Text>

        {/* Section 1 */}
        <Heading as="h2" size="md" mt={6} mb={2}>
          Acceptance of Terms
        </Heading>
        <Text mb={4}>
          By accessing and using LEVELCHECK.AI, you agree to be bound by these
          Terms of Service.
        </Text>

        {/* Section 2 */}
        <Heading as="h2" size="md" mt={6} mb={2}>
          Use of the AI Cognitive Developent Test
        </Heading>
        <Text mb={4}>
          This is provided for informational and entertainment purposes only. It
          is not a replacement for a Psychologist. You agree not to use the
          Chatbot for any unlawful purpose or in any way that could damage,
          disable, overburden, or impair it.
        </Text>

        {/* Section 3 */}
        <Heading as="h2" size="md" mt={6} mb={2}>
          Intellectual Property
        </Heading>
        <Text mb={4}>
          All content provided by the Chatbot, including but not limited to
          text, graphics, and code, is owned by LEVELCHECK.AI and is protected
          by copyright and intellectual property laws.
        </Text>

        <Text>
          If you have questions about our terms of service, please email us at{" "}
          <Link href="mailto:support@levelcheck.ai" color="blue.500">
            support@levelcheck.ai
          </Link>
          .
        </Text>
      </Container>
      <Footer />
    </Box>
  );
};

export default TermsOfServicePage;
