//src/ResultsPage.js
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Link,
  Progress,
  Select,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { assessmentNames } from "./AssessmentConstants";
import NavigationBar from "./NavigationBar";
import PaymentModal from "./PaymentModal";
import { auth, db } from "./firebase-config";

const ResultsPage = () => {
  const navigate = useNavigate();
  const [resultsData, setResultsData] = useState({});
  const [selectedAssessment, setSelectedAssessment] = useState("");
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isOverallAssessmentLoading, setIsOverallAssessmentLoading] =
    useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [hasPaid, setHasPaid] = useState(false);

  // Function to find the next incomplete assessment
  const findNextIncompleteAssessment = () => {
    const completedAssessments = Object.keys(resultsData).map(Number);
    const assessmentKeys = Object.keys(assessmentNames).map(Number);

    const nextAssessment = assessmentKeys.find(
      (key) => !completedAssessments.includes(key)
    );

    return nextAssessment ? String(nextAssessment) : null;
  };

  // Navigate to the next incomplete assessment
  const navigateToNextAssessment = () => {
    const nextAssessment = findNextIncompleteAssessment();
    if (nextAssessment) {
      navigate(`/psychological_assessment/${nextAssessment}`);
    }
  };

  const checkIfAllAssessmentsCompleted = (results) => {
    // Get the number of keys in the assessmentNames object
    const requiredAssessments = Array.from(
      { length: Object.keys(assessmentNames).length },
      (_, i) => i + 1
    );

    const allCompleted = requiredAssessments.every((num) =>
      results.hasOwnProperty(num)
    );
    return allCompleted;
  };

  const fetchOverallAssessment = async (userId) => {
    const flaskUrl = process.env.REACT_APP_FLASK_URL;
    setIsOverallAssessmentLoading(true);
    try {
      const response = await axios.post(
        `${flaskUrl}/generate_overall_assessment`,
        { userId }
      );

      if (response.status === 200) {
        const overallAssessment = response.data.overallAssessment;
        // console.log("Received overall assessment:", overallAssessment);

        setResultsData((prevResultsData) => ({
          ...prevResultsData,
          88: overallAssessment,
        }));
      } else {
        console.error(
          "Failed to fetch overall assessment, status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error fetching overall assessment:", error);
    } finally {
      setIsOverallAssessmentLoading(false);
    }
  };

  const renderAssessmentNavigationButton = () => {
    const nextAssessmentExists = findNextIncompleteAssessment();

    if (!hasPaid) {
      return (
        <Button
          colorScheme="blue"
          isDisabled
          onClick={() => setShowPaymentModal(true)}
        >
          Go to Next Incomplete Assessment
        </Button>
      );
    }

    if (nextAssessmentExists) {
      return (
        <Button colorScheme="blue" onClick={navigateToNextAssessment}>
          Go to Next Incomplete Assessment
        </Button>
      );
    } else {
      return (
        <Button colorScheme="green" isDisabled>
          All Assessments Completed
        </Button>
      );
    }
  };

  useEffect(() => {
    let unsubscribe = () => {};
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setHasPaid(userData.paid);
            if (!userData.paid) {
              setShowPaymentModal(true);
            }
          }
        });

        const q = query(
          collection(db, "assessmentResults"),
          where("userId", "==", user.uid)
        );

        unsubscribe = onSnapshot(q, async (querySnapshot) => {
          let fetchedResults = {};
          let maxTimestamps = {};
          let latestOverallAssessmentTimestamp = 0;
          let latestIndividualAssessmentTimestamp = 0;

          querySnapshot.docs.forEach((doc) => {
            const data = doc.data();
            const assessmentNumber = data.assessmentNumber;
            const timestamp = data.timestamp;

            // Check if it's the most recent assessment for this number
            if (
              !maxTimestamps[assessmentNumber] ||
              maxTimestamps[assessmentNumber] < timestamp
            ) {
              maxTimestamps[assessmentNumber] = timestamp;

              if (assessmentNumber === 88) {
                latestOverallAssessmentTimestamp = timestamp;
              } else {
                latestIndividualAssessmentTimestamp = Math.max(
                  latestIndividualAssessmentTimestamp,
                  timestamp
                );
              }

              try {
                data.results = JSON.parse(data.results);
                fetchedResults[assessmentNumber] = data.results;
              } catch (error) {
                console.error("Error parsing results: ", error);
              }
            }
          });

          if (Object.keys(fetchedResults).length > 0) {
            setResultsData(fetchedResults);

            // Find and set the most recent individual assessment for dropdown
            const mostRecentAssessment = Object.keys(fetchedResults)
              .filter((key) => key !== "88")
              .sort((a, b) => maxTimestamps[b] - maxTimestamps[a])[0];
            setSelectedAssessment(mostRecentAssessment);
            setIsDropdownDisabled(false);

            // Check if all assessments are completed and if the overall assessment needs updating
            if (
              checkIfAllAssessmentsCompleted(fetchedResults) &&
              latestIndividualAssessmentTimestamp >
                latestOverallAssessmentTimestamp
            ) {
              await fetchOverallAssessment(user.uid);
            }
          }
        });
      }
    });

    return () => {
      unsubscribeAuth();
      unsubscribe();
    };
  }, []);

  // New useEffect for logging state changes
  useEffect(() => {
    // console.log("Current state of resultsData:", resultsData);
    // console.log("Current state of selectedAssessment:", selectedAssessment);
  }, [resultsData, selectedAssessment]);

  const renderOverallAssessmentText = () => {
    if (isOverallAssessmentLoading) {
      return (
        <Box textAlign="center" my="3">
          <Spinner size="sm" />
          <Text mt="2">Evaluating Overall Assessment...</Text>
        </Box>
      );
    }

    const overallAssessment = resultsData["88"];
    if (overallAssessment) {
      return (
        <VStack
          textAlign="center"
          mb="4"
          bg="gray.100"
          borderColor="blue.500"
          borderWidth="1px"
          borderRadius="lg"
          p="4"
          spacing="4"
          shadow="md"
        >
          <Text as="h4" fontSize="lg" fontWeight="bold" color="gray.800">
            Overall Cognitive Development Level: {overallAssessment.level}
          </Text>
          <Progress
            value={overallAssessment.level}
            max={9}
            colorScheme="green"
            height="24px"
            borderRadius="md"
            width="full"
          />
          <Text mt="3" color="gray.600">
            {overallAssessment.reasoning}
          </Text>

          {/* Enhanced Bar Graph with Assessment Names */}
          {Object.keys(resultsData)
            .filter((key) => key !== "88")
            .map((key, index) => {
              const value = resultsData[key].level;
              const assessmentName =
                assessmentNames[key] || `Assessment ${key}`;
              return (
                <HStack
                  key={index}
                  width="full"
                  alignItems="center"
                  spacing="2"
                >
                  <Text
                    width="30%"
                    color="gray.700"
                    textAlign="left"
                    fontSize="sm"
                    fontWeight="bold"
                  >
                    {key}: {assessmentName}
                  </Text>
                  <Box width="100%" bg="gray.300" p="1" borderRadius="md">
                    <Box
                      bg="blue.500"
                      width={`${value * 11.1}%`}
                      h="10px"
                      borderRadius="md"
                    ></Box>
                  </Box>
                </HStack>
              );
            })}
        </VStack>
      );
    }

    return null;
  };

  const renderResults = () => {
    const result = resultsData[selectedAssessment];
    if (!result) {
      return (
        <Alert status="info" color="gray.600">
          <AlertIcon />
          No results available for this assessment.
        </Alert>
      );
    }

    return (
      <>
        <Text textAlign="center" fontSize="lg" fontWeight="bold">
          Assessment {selectedAssessment} Cognitive Development Level:{" "}
          {result.level}
        </Text>
        <Progress value={result.level} max={9} colorScheme="blue" />
        <Text mt="3">{result.reasoning}</Text>
      </>
    );
  };

  return (
    <>
      <NavigationBar isAuthenticated={isAuthenticated} />
      <Container my="4" maxW="5xl">
        <VStack spacing="4" align="stretch">
          <Box
            bg="background.secondary"
            textAlign="center"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            w="full"
          >
            <Heading as="h2" size="xl" p="4">
              Results
            </Heading>
            <Box p="4">
              {renderOverallAssessmentText()}
              {Object.keys(resultsData).filter((key) => key !== "88").length >
                0 && (
                <FormControl id="selectAssessment" mt="5">
                  <FormLabel fontWeight="bold" fontSize="1.2rem">
                    Select Individual Assessment
                  </FormLabel>
                  <Select
                    value={selectedAssessment}
                    onChange={(e) => setSelectedAssessment(e.target.value)}
                    isDisabled={isDropdownDisabled}
                    cursor="pointer"
                    borderColor="#007bff"
                    bg="#f8f9fa"
                    color="#000"
                    fontWeight="bold"
                  >
                    <option
                      value=""
                      disabled
                      style={{ color: "grey", fontStyle: "italic" }}
                    >
                      Choose an Assessment...
                    </option>
                    {Object.keys(resultsData)
                      .filter((key) => key !== "88")
                      .map((key) => (
                        <option key={key} value={key}>
                          Assessment {key}:{" "}
                          {assessmentNames[key] || `Assessment ${key}`}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              )}
              {renderResults()}
            </Box>
          </Box>
        </VStack>
        <Flex justifyContent="end" mt="4">
          {renderAssessmentNavigationButton()}
        </Flex>
      </Container>

      <Container my={1} maxW="1000px" fontSize="0.9rem" p={5}>
        <Text mt={1} textAlign="center">
          Join the
          <Link
            href="https://discord.gg/6aqpJXawnY"
            isExternal
            color="blue.500"
            ml={1}
          >
            Discord server
          </Link>{" "}
          to connect with others! <br />
          You will be added to a private group chat with others of the same
          level 1-2 days after the completion of your overall assessment.
        </Text>
      </Container>

      {/* Disclaimer Box */}
      <Divider borderColor="#DDD" my={6} />

      <Container my={1} maxW="1000px" fontSize="0.9rem" p={5}>
        <Text color="gray.600">
          When using an AI cognitive development assessment, it's crucial to
          understand both its benefits and limitations. These assessments can
          provide insights into personal growth, moral reasoning, and
          self-awareness. However, they are not comprehensive evaluations of
          one's psychological state. The interpretation of responses can be
          subjective and context-dependent. While AI is useful for organizing
          and analyzing responses, it cannot replace the empathetic judgment and
          nuanced understanding of a human psychologist. AI tools might not
          fully capture the complexities of human emotions and experiences. If
          you are experiencing distress or seeking a thorough understanding of
          your psychological state, it is important to consult a qualified
          psychologist. AI assessments are valuable tools but are not a
          replacement for professional psychological services.
        </Text>
      </Container>
      <PaymentModal
        isOpen={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        email={auth.currentUser?.email}
        userId={auth.currentUser?.uid}
      />
    </>
  );
};

export default ResultsPage;
