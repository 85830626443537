// src/HomePage.js
import {
  AspectRatio,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { doc, getDoc } from "firebase/firestore";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import NavigationBar from "./NavigationBar";
import PaymentModal from "./PaymentModal";
import { auth, db } from "./firebase-config";

const MotionBox = motion(Box);

const HomePage = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setIsAuthenticated(!!user);
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  const checkPaymentStatusAndShowModal = async () => {
    if (currentUser) {
      const userDocRef = doc(db, "users", currentUser.uid);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists() && !docSnap.data().paid) {
        setShowPaymentModal(true);
      } else {
        navigate("/assessment_selection");
      }
    } else {
      navigate("/register");
    }
  };

  const handleButtonClick = () => {
    if (isAuthenticated) {
      checkPaymentStatusAndShowModal();
    } else {
      navigate("/register");
    }
  };

  const handleSignOut = () => {
    auth
      .signOut()
      .then(() => {
        setIsAuthenticated(false);
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  const bgImageUrl = "url('/universe.png')";

  return (
    <Box
      style={{
        backgroundImage: `${bgImageUrl}, linear-gradient(black, black)`,
        backgroundRepeat: "no-repeat, repeat",
        backgroundSize: "auto, cover",
        backgroundPosition: "center 28%",
        "@media (min-width: 768px)": {
          backgroundSize: "auto, cover",
        },
        "@media (min-width: 1024px)": {
          backgroundPosition: "center bottom, center bottom",
        },
        "@media (max-width: 767px)": {
          backgroundPosition: "center top, center top",
        },
      }}
    >
      <NavigationBar
        isAuthenticated={isAuthenticated}
        handleSignOut={handleSignOut}
      />
      <MotionBox
        height="500px"
        position="relative"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
        py={10}
        px={4}
        textAlign="center"
        whileHover={{ scale: 1.05 }}
        id="__next"
      >
        <VStack
          spacing={16}
          position="absolute"
          top="60%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Heading
            fontSize={["4xl", "5xl", "6xl"]}
            fontWeight="extrabold"
            fontFamily="heading"
            // bgGradient="linear(to-l, brand.gradientStart, brand.gradientEnd)"
            // color="transparent"
            // bgClip="text"
            color="brand.gradientEnd"
            textShadow="1px 1px 4px rgba(0, 0, 0, 0.5)"
          >
            UNLEASH YOUR POTENTIAL <br /> FIND YOUR LEVEL
          </Heading>
          <Box height="20" />
          <Heading
            as="h2"
            size={["md", "lg", "xl"]}
            fontFamily="heading"
            textAlign="center"
            color="text.body"
            textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
          >
            Cognitive Development Test
          </Heading>
        </VStack>
      </MotionBox>
      <Container maxW="container.xl" py={6} mt="14">
        <Flex direction="column" align="center" justify="center" py={10}>
          <Button
            size="lg"
            bg="brand.800"
            color="white"
            _hover={{ bg: "brand.700" }}
            onClick={handleButtonClick}
          >
            Purchase & Register for Assessment
          </Button>
        </Flex>
        <Flex direction={{ base: "column", md: "row" }} gap={6}>
          <AspectRatio ratio={16 / 9} w={{ base: "100%", md: "50%" }}>
            <iframe
              title="Cognitive Development Video"
              src="https://www.youtube.com/embed/kse87ocS0Uo"
              allowFullScreen
              boxShadow="xl"
              rounded="lg"
              overflow="hidden"
            />
          </AspectRatio>
          <Box
            p={6}
            boxShadow="xl"
            rounded="lg"
            bg="rgba(27, 32, 43, 0.85)"
            w={{ base: "100%", md: "50%" }}
          >
            <Text fontFamily="body" fontWeight="bold">
              Do you think differently from others? Do you want to know why?
            </Text>
            <br />
            LevelCheck is an AI test for your psychological development.
            <br />
            <br />
            <Text fontFamily="body">
              <Link
                href="https://en.wikipedia.org/wiki/Cognitive_development"
                isExternal
                color="teal.500"
              >
                Numerous researchers
              </Link>{" "}
              have studied cognitive development. Their work has transformed the
              way that human beings relate to each other. We can now apply their
              work to measure your mental and emotional maturity using the
              latest in AI technology.
              <br />
              <br />
              LevelCheck is informed by Ken Wilber's Integral Theory (a holistic
              map of consciousness) to identify your stages of development
              across multiple aspects of your mind.
              <br />
              <br />
              LevelCheck opens the door to deeper self-awareness, helping you
              harness your potential. Knowing your level can enhance your
              relationships, inform your decisions, improve your overall
              well-being, and speed up your growth.
              <br />
              <br />
              Take this opportunity to understand yourself more than ever.
            </Text>
          </Box>
        </Flex>

        <Flex direction="column" align="center" justify="center" py={10}>
          <Heading
            as="h3"
            size="lg"
            textAlign="center"
            mb={4}
            color="text.body"
          >
            About the Creators
          </Heading>
          <Text color="text.body" px={{ base: 4, md: 8 }} textAlign="center">
            Levelcheck is a collaboration between a psychology content creator
            and a couple AI engineers. The engineers are brothers, one has a PhD
            from Waterloo, the other has done time in Fort McMurray. The content
            creator gained expertise in Integral Theory (Ken Wilber's map of
            human consciousness) during grad school. His silly name has gotten
            him a lot of notice very quickly - don't let it fool you! He knows
            what he's talking about. The video is an example of his work, and
            more can be found{" "}
            <Link href="https://linktr.ee/hoe_math" isExternal color="teal.500">
              here
            </Link>
            .
          </Text>
        </Flex>
      </Container>
      <Footer />
      <PaymentModal
        isOpen={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        email={currentUser?.email}
        userId={currentUser?.uid}
      />
    </Box>
  );
};

export default HomePage;
