// src/LoginPage.js
import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavigationBar from "./NavigationBar";
import PasswordResetModal from "./PasswordResetModal";
import PaymentModal from "./PaymentModal";
import { db } from "./firebase-config";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Correct this line
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const navigate = useNavigate();

  const signIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Check if user has paid
      const userDoc = doc(db, "users", userCredential.user.uid);
      const docSnap = await getDoc(userDoc);

      if (docSnap.exists() && docSnap.data().paid) {
        handleRememberMe();
        navigate("/");
      } else {
        setShowPaymentModal(true);
      }
    } catch (error) {
      console.error("Error signing in with email and password", error);
      setErrorMessage("Failed to sign in. Please check your credentials.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRememberMe = () => {
    if (rememberMe) {
      localStorage.setItem("rememberedEmail", email);
    } else {
      localStorage.removeItem("rememberedEmail");
    }
  };

  return (
    <Box>
      <NavigationBar />
      <Flex
        align="center"
        justify="center"
        py={12}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        minHeight="100vh"
      >
        <Container
          maxW="container.sm"
          bg="background.secondary"
          p={8}
          borderRadius="lg"
          boxShadow="lg"
        >
          <VStack spacing={4}>
            <Heading as="h1" size="xl" textAlign="center">
              Sign In
            </Heading>
            {errorMessage && <Text color="red.500">{errorMessage}</Text>}
            <Text>Please enter your username and password.</Text>
            <Box as="form" w="full" onSubmit={signIn}>
              <Stack spacing={4}>
                <FormControl isRequired>
                  <FormLabel htmlFor="username-email">Username</FormLabel>
                  <Input
                    id="username-email"
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <Input
                    id="password"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
                <Checkbox
                  id="remember-me"
                  isChecked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                >
                  Remember Me
                </Checkbox>
                <Button
                  type="submit"
                  colorScheme="blue"
                  size="lg"
                  w="full"
                  isLoading={isLoading}
                  loadingText="Signing In"
                  disabled={isLoading}
                >
                  Sign In
                </Button>
              </Stack>
            </Box>
            <Button
              variant="link"
              onClick={() => setShowModal(true)}
              colorScheme="blue"
            >
              Forgot Password?
            </Button>
          </VStack>
        </Container>
      </Flex>
      <PasswordResetModal showModal={showModal} setShowModal={setShowModal} />

      {/* Payment Modal */}
      <PaymentModal
        isOpen={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        email={email}
        userId={currentUser?.uid}
        setError={setErrorMessage}
        setIsLoading={setIsLoading}
      />
    </Box>
  );
};

export default LoginPage;
