// src/App.js
import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import AssessmentSelectionPage from "./AssessmentSelectionPage";
import { AuthProvider } from "./AuthContext";
import DemographicsForm from "./DemographicsForm";
import FAQPage from "./FAQPage";
import HomePage from "./HomePage";
import LoginPage from "./LoginPage";
import NotFoundPage from "./NotFoundPage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import PsychologicalAssessmentPage from "./PsychologicalAssessmentPage";
import RegisterPage from "./RegisterPage";
import ResultsPage from "./ResultsPage";
import TermsOfServicePage from "./TermsOfServicePage";
import customTheme from "./customTheme";

const App = () => {
  return (
    <ChakraProvider theme={customTheme}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route
              path="/assessment_selection"
              element={<AssessmentSelectionPage />}
            />
            <Route
              path="/psychological_assessment/:assessmentKey"
              element={<PsychologicalAssessmentPage />}
            />
            <Route path="/results" element={<ResultsPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/demographics" element={<DemographicsForm />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
};

export default App;
