// src/NavigationBar.js
import { Box, Button, Flex, Link, Stack, Text } from "@chakra-ui/react";
import { getAuth, signOut } from "firebase/auth";
import React, { useContext } from "react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

const NavigationBar = ({ handleSignOut: propHandleSignOut }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const internalHandleSignOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  const handleSignOut = propHandleSignOut || internalHandleSignOut;

  return (
    <Box
      bgRepeat="no-repeat"
      bgSize={{ base: "auto 100%", md: "cover" }}
      bgPosition={{ base: "center top", md: "center" }}
      color="white"
      py={{ base: 2 }}
      px={{ base: 4 }}
      align="center"
      justify="center"
    >
      <Flex minH={"60px"} align={"center"} justify={"space-between"}>
        <Stack direction={"row"} spacing={{ base: 2, md: 4 }} align={"center"}>
          <Text
            fontSize={{ base: "lg", md: "xl" }}
            fontWeight="bold"
            as={ReactRouterLink}
            to="/"
          >
            HOME
          </Text>
          <Link as={ReactRouterLink} to="/assessment_selection" px={2} py={1}>
            Level Test
          </Link>
          <Link as={ReactRouterLink} to="/faq" px={2} py={1}>
            FAQ
          </Link>
          {isAuthenticated && (
            <Link as={ReactRouterLink} to="/results" px={2} py={1}>
              My Results
            </Link>
          )}
        </Stack>
        {isAuthenticated ? (
          <Button variant="outline" onClick={handleSignOut} color="blue.500">
            Sign Out
          </Button>
        ) : (
          <Stack direction={"row"} spacing={4} align={"center"}>
            <Link as={ReactRouterLink} to="/login" px={2} py={1}>
              Sign In
            </Link>
            <Link as={ReactRouterLink} to="/register" px={2} py={1}>
              Register
            </Link>
          </Stack>
        )}
      </Flex>
    </Box>
  );
};

export default NavigationBar;
