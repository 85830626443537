// src/AssessmentConstants.js

export const assessmentQuestions = {
  1: "What do you think are the most important qualities in a romantic relationship?",
  2: "What makes someone successful?",
  3: "How have your beliefs and values changed over time, and what experiences or insights contributed to these changes?",
  4: "Do you follow the rules at school or work? Why or why not?",
  5: "Do you think you can control what happens in your future? Why or why not?",
  // 3: "Can you tell me about an important event that happened in your life. Why was it important?",
};

export const assessmentNames = {
  1: "Relationships",
  2: "Success",
  3: "Change",
  4: "Rules",
  5: "Personal Agency",
};
