// src/utils/paymentUtils.js
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const redirectToCheckout = async (
  email,
  userId,
  setError,
  setIsLoading
) => {
  try {
    // console.log("Email:", email, "UserID:", userId);

    const flaskUrl = process.env.REACT_APP_FLASK_URL;
    const response = await axios.post(`${flaskUrl}/create-checkout-session`, {
      email,
      user_id: userId,
    });
    const session = response.data;
    const stripe = await stripePromise;
    await stripe.redirectToCheckout({ sessionId: session.id });
  } catch (error) {
    console.error("Error in Stripe Checkout:", error);
    setError &&
      setError(
        <span style={{ color: "darkred" }}>
          Payment process failed. Please try again.
        </span>
      );
    setIsLoading && setIsLoading(false);
  }
};
