import { Box, Container, Flex, Link, Text } from "@chakra-ui/react";
import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box bg="background.secondary" color="text.body" py={4}>
      <Container maxW="container.xl">
        <Flex justify="space-between" align="center">
          {/* Address and Brand Information */}
          <Flex direction="column" align="flex-start">
            <Text fontSize="sm">
              330 5th Avenue SW, Calgary, Alberta, T2P 0L4, Canada
            </Text>
            <Text fontSize="sm" mt={2}>
              &copy; {currentYear} LEVELCHECK.AI. All rights reserved.
            </Text>
          </Flex>

          {/* Links for legal pages and direct email contact */}
          <Flex>
            <Link href="/privacy-policy" mx={2}>
              Privacy Policy
            </Link>
            <Link href="/terms-of-service" mx={2}>
              Terms of Service
            </Link>
            <Link href="mailto:support@levelcheck.ai" mx={2}>
              Contact Us
            </Link>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
