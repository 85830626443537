import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDWEUnbY0q7LETtZmrer_HvpEb3TZ5cJH8",
  authDomain: "levels-app-22ced.firebaseapp.com",
  projectId: "levels-app-22ced",
  storageBucket: "levels-app-22ced.appspot.com",
  messagingSenderId: "1073913351709",
  appId: "1:1073913351709:web:7a56582b4d904c4a0f80f4",
  measurementId: "G-3SY0VJFSSP",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { auth, db };
