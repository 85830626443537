// src/PsychologicalAssessmentPage.js
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Spinner,
  Text,
  Textarea,
} from "@chakra-ui/react";
import axios from "axios";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { assessmentQuestions } from "./AssessmentConstants";
import NavigationBar from "./NavigationBar";
import { auth, db } from "./firebase-config";

function PsychologicalAssessmentPage() {
  const navigate = useNavigate();
  const { assessmentKey } = useParams();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [assessmentType] = useState("");
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerating] = useState(false);
  const [error, setError] = useState(null);
  const textareaRef = useRef(null);
  const [question, setQuestion] = useState(assessmentQuestions[assessmentKey]);
  const [assessmentCompleted, setAssessmentCompleted] = useState(false);
  const [redoLimitReached, setRedoLimitReached] = useState(false);
  const [hasQuestionBeenSaved, setHasQuestionBeenSaved] = useState(false);

  const checkUserPaymentStatus = async (userId) => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        return userDocSnap.data().paid || false;
      }
      return false;
    } catch (error) {
      console.error("Error checking user payment status:", error);
      throw error;
    }
  };
  const isSavingQuestionRef = useRef(false);

  const saveQuestionToFirestore = useCallback(
    async (questionKey, questionText) => {
      if (isSavingQuestionRef.current) return; // Use ref to check if save operation is in progress

      isSavingQuestionRef.current = true; // Mark as saving

      const questionExistsQuery = query(
        collection(db, "messages"),
        where("userId", "==", auth.currentUser.uid),
        where("assessmentNumber", "==", questionKey),
        where("message.role", "==", "assistant"),
        where("message.content", "==", questionText),
        where("archived", "==", false),
        limit(1)
      );

      try {
        const querySnapshot = await getDocs(questionExistsQuery);
        if (querySnapshot.empty) {
          // console.log(
          //   `Saving question to Firestore. Question key: ${questionKey}, Question text: ${questionText}`
          // );
          await addDoc(collection(db, "messages"), {
            userId: auth.currentUser.uid,
            assessmentNumber: questionKey,
            message: { role: "assistant", content: questionText },
            archived: false,
            timestamp: new Date(),
          });
          // No need to set a ref here for completion as the logic after saving does not depend on it
        } else {
          // console.log(
          //   `Question with key ${questionKey} already exists in Firestore. Skipping save.`
          // );
        }
      } catch (error) {
        console.error("Error in Firestore operation: ", error);
      } finally {
        isSavingQuestionRef.current = false; // Reset ref regardless of operation outcome
      }
    },
    // Dependency array can now be empty because refs don’t require cleanup and don’t trigger re-renders
    []
  );

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      // console.log("Auth state changed: ", user);
      setIsAuthenticated(!!user);
    });
  }, []);

  useEffect(() => {
    const checkAccessAndSetup = async () => {
      if (!auth.currentUser) {
        navigate("/login");
        return;
      }

      const isPaid = await checkUserPaymentStatus(auth.currentUser.uid);
      if (!isPaid) {
        // Redirect to payment page or show an error/alert message
        navigate("/payment"); // Redirect to a payment page, for example
        return;
      }

      const flaskUrl = process.env.REACT_APP_FLASK_URL;
      const checkAssessmentState = async () => {
        try {
          const response = await axios.get(
            `${flaskUrl}/check_assessment_state`,
            {
              params: {
                userId: auth.currentUser.uid,
                assessmentNumber: assessmentKey,
              },
            }
          );
          if (response.data.isCompleted && response.data.redoLimitReached) {
            console.log(
              "You have reached the limit for redoing the assessment."
            );
            setAssessmentCompleted(true);
            setRedoLimitReached(true);
          } else if (response.data.isCompleted) {
            setAssessmentCompleted(true);
            setRedoLimitReached(false);
            console.log("You can redo the assessment.");
          }
        } catch (error) {
          console.error("Error checking assessment state:", error);
        }
      };

      setIsLoading(true);
      await checkAssessmentState();

      const q = query(
        collection(db, "messages"),
        where("userId", "==", auth.currentUser.uid),
        where("assessmentNumber", "==", assessmentKey),
        where("archived", "==", false),
        where("message.role", "==", "assistant"),
        orderBy("timestamp", "desc"),
        limit(1)
      );

      const unsubscribe = onSnapshot(
        q,
        async (querySnapshot) => {
          const messages = querySnapshot.docs.map((doc) => doc.data());
          if (messages.length > 0) {
            setQuestion(messages[0].message.content);
            setHasQuestionBeenSaved(false); // Reset flag when question changes
          } else if (!hasQuestionBeenSaved) {
            // console.log("About to call saveQuestionToFirestore...");
            await saveQuestionToFirestore(
              assessmentKey,
              assessmentQuestions[assessmentKey]
            );
            setQuestion(assessmentQuestions[assessmentKey]);
          }
          setIsLoading(false);
        },
        (error) => {
          console.error(
            `Error fetching responses for userID: ${auth.currentUser.uid}, assessmentNumber: ${assessmentKey}:`,
            error
          );
          setError("Error fetching responses.");
          setIsLoading(false);
        }
      );

      return () => unsubscribe();
    };

    checkAccessAndSetup();
  }, [assessmentKey, navigate, saveQuestionToFirestore, hasQuestionBeenSaved]);

  const handleSendResponse = async (e) => {
    e.preventDefault();
    if (!response.trim()) {
      setError(
        <span style={{ color: "darkred" }}>
          Please provide a response before proceeding.
        </span>
      );
      return;
    }

    setIsLoading(true);
    try {
      await addMessageToFirestore(response);
      await postMessageToFlask(response);
      setResponse("");
    } catch (error) {
      console.error("Error handling response: ", error);
      setError("Error handling response.");
    } finally {
      setIsLoading(false);
    }
  };

  const addMessageToFirestore = async (message) => {
    try {
      await addDoc(collection(db, "messages"), {
        userId: auth.currentUser.uid,
        assessmentNumber: assessmentKey,
        archived: false,
        timestamp: new Date(),
        message: {
          role: "user",
          content: message,
        },
      });
    } catch (error) {
      console.error("Error saving message to Firestore: ", error);
      console.error("Message: ", message);
      console.error("auth.currentUser.uid: ", auth.currentUser.uid);
      console.error("assessmentKey: ", assessmentKey);
      throw error;
    }
  };

  const postMessageToFlask = async (message) => {
    try {
      const flaskUrl = process.env.REACT_APP_FLASK_URL;
      const response = await axios.post(`${flaskUrl}/handle_new_message`, {
        userId: auth.currentUser.uid,
        assessmentNumber: assessmentKey,
        message: {
          role: "user",
          content: message,
        },
      });
      if (response.data.assessmentComplete) {
        setAssessmentCompleted(true);
        navigate("/results");
      }
      return response.data;
    } catch (error) {
      console.error("Error posting message to Flask: ", error);
      throw error;
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleRedoAssessment = async () => {
    try {
      const flaskUrl = process.env.REACT_APP_FLASK_URL;
      const response = await axios.post(`${flaskUrl}/reset_assessment`, {
        userId: auth.currentUser.uid,
        assessmentNumber: assessmentKey,
      });

      // Check if the response indicates a successful reset
      if (response.status === 200) {
        // Reset assessment related states
        setAssessmentCompleted(false);
        setRedoLimitReached(false);
        setError(null);
        setQuestion(assessmentQuestions[assessmentKey]);
        navigate(`/psychological_assessment/${assessmentKey}`);
      } else {
        console.error("Failed to reset assessment:", response);
      }
    } catch (error) {
      console.error("Error resetting assessment:", error);
      setError("Failed to reset assessment. Please try again.");
    }
  };

  return (
    <Container maxW="container.xl">
      <NavigationBar
        isAuthenticated={isAuthenticated}
        handleSignOut={handleSignOut}
      />
      <Box my={5}>
        <Heading as="h1" size="lg" textAlign="center" mb={4}>
          Psychological Assessment {assessmentType || assessmentKey}
        </Heading>

        {error && (
          <Alert status="error">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle style={{ color: "darkred" }}>Error!</AlertTitle>
              <AlertDescription display="block">{error}</AlertDescription>
            </Box>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={() => setError(null)}
            />
          </Alert>
        )}

        {isLoading && (
          <Box textAlign="center">
            <Spinner />
            <Text>Loading your assessment...</Text>
          </Box>
        )}

        {!assessmentCompleted && (
          <Box as="form" onSubmit={handleSendResponse}>
            <FormControl id="userResponse" mb={4}>
              <FormLabel>{question}</FormLabel>
              <Textarea
                ref={textareaRef}
                value={response}
                onChange={(e) => setResponse(e.target.value)}
                placeholder="Your response..."
                size="lg"
                rows={4}
              />
            </FormControl>
            <Button
              colorScheme="blue"
              type="submit"
              isLoading={isLoading || isGenerating}
            >
              Send
            </Button>
          </Box>
        )}

        {assessmentCompleted && !redoLimitReached && (
          <Text textAlign="center" my={4}>
            Assessment completed. You can view your results or redo the
            assessment.
            <Button onClick={() => navigate("/results")} ml={2}>
              View Results
            </Button>
            <Button onClick={handleRedoAssessment} colorScheme="green" ml={2}>
              Redo Assessment
            </Button>
          </Text>
        )}

        {assessmentCompleted && redoLimitReached && (
          <Text textAlign="center" my={4}>
            You have reached the limit for redoing the assessment. View your
            results.
            <Button
              onClick={() => navigate("/results")}
              colorScheme="blue"
              ml={2}
            >
              View Results
            </Button>
          </Text>
        )}
      </Box>

      <Box my={3} color="gray.600">
        <Text fontSize="sm">
          Note: It's important to answer questions with at least a couple of
          sentences, even if they are difficult. Your thoughts and feelings are
          valuable and can provide insights into your psychological state. If
          you're stuck, try to explain your thought process or why you find the
          question challenging.
        </Text>
      </Box>
    </Container>
  );
}

export default PsychologicalAssessmentPage;
