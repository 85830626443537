// src/AssessmentSelectionPage.js
import {
  Badge,
  Box,
  Container,
  Heading,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { assessmentNames } from "./AssessmentConstants";
import NavigationBar from "./NavigationBar";
import PaymentModal from "./PaymentModal";
import { auth, db } from "./firebase-config";

function AssessmentSelectionPage() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [assessmentStatus, setAssessmentStatus] = useState(
    createInitialAssessmentStatus()
  );
  const [hasPaid, setHasPaid] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  function createInitialAssessmentStatus() {
    return Object.keys(assessmentNames).reduce((status, key) => {
      status[key] = { completed: false };
      return status;
    }, {});
  }

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
      if (user) {
        const fetchAssessmentStatus = (userId) => {
          const q = query(
            collection(db, "assessmentResults"),
            where("userId", "==", userId)
          );

          return onSnapshot(q, (querySnapshot) => {
            setAssessmentStatus((currentStatus) => {
              const statusUpdate = { ...currentStatus };
              querySnapshot.forEach((docSnapshot) => {
                const data = docSnapshot.data();
                if (statusUpdate[data.assessmentNumber]) {
                  statusUpdate[data.assessmentNumber].completed = true;
                }
              });
              return statusUpdate;
            });
          });
        };

        // Update this to use the doc and onSnapshot methods from Firebase v9+
        const userDocRef = doc(db, "users", user.uid);
        const unsubscribeUserDoc = onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists() && docSnapshot.data().paid) {
            setHasPaid(true);
          } else {
            setHasPaid(false);
          }
        });

        const unsubscribeStatus = fetchAssessmentStatus(user.uid);
        return () => {
          unsubscribeUserDoc();
          unsubscribeStatus();
        };
      } else {
        setHasPaid(false);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  const handleSelect = (assessmentKey) => {
    if (isAuthenticated) {
      if (hasPaid) {
        navigate(`/psychological_assessment/${assessmentKey}`);
      } else {
        setShowPaymentModal(true);
      }
    } else {
      navigate(`/login`);
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const listBg = useColorModeValue("gray.100", "gray.700");
  const hoverBg = useColorModeValue("gray.200", "gray.600");
  const MotionBox = motion(Box);

  return (
    <>
      <NavigationBar
        isAuthenticated={isAuthenticated}
        handleSignOut={handleSignOut}
      />
      <MotionBox
        minH="100vh"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        color="white"
        py={10}
        px={4}
        textAlign="center"
        whileHover={{ scale: 1.05 }}
        id="__next"
      >
        <Container my={6}>
          <Box textAlign="center" mb={4}>
            <Heading>Select Assessment</Heading>
            <List spacing={3} my={4}>
              {Object.entries(assessmentStatus).map(
                ([assessmentKey, status]) => (
                  <ListItem
                    key={assessmentKey}
                    p={2}
                    bg={listBg}
                    rounded="md"
                    shadow="base"
                    _hover={{ bg: hoverBg, cursor: "pointer" }}
                    onClick={() => handleSelect(assessmentKey)}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text color="brand.900">
                      {assessmentNames[assessmentKey] ||
                        `Assessment ${assessmentKey}`}
                    </Text>
                    <Badge colorScheme={status.completed ? "green" : "gray"}>
                      {status.completed ? "Completed" : "Not Completed"}
                    </Badge>
                  </ListItem>
                )
              )}
            </List>
          </Box>
        </Container>
      </MotionBox>
      {/* Payment Modal */}
      <PaymentModal
        isOpen={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        email={auth.currentUser?.email}
        userId={auth.currentUser?.uid}
      />
    </>
  );
}

export default AssessmentSelectionPage;
