// src/customTheme.js
import { extendTheme } from "@chakra-ui/react";

// Define the colors used in the example application
const colors = {
  brand: {
    900: "#254898", // Dark blue for text or subdued elements
    800: "#215CE3", // Primary blue color, possibly for buttons or interactive elements
    700: "#4C83FF", // Lighter blue, could be used for hover states or accents
    600: "#7AA1FA", // Even lighter blue, suitable for backgrounds or gradients
    gradientStart: "#7a33c9", // Bit More Bolder Lavender color
    gradientEnd: "#5041c7", // Bit More Bolder Blue color
  },
  background: {
    primary: "#181922", // Dark background color
    secondary: "#1B202B", // Secondary background color for boxes
  },
  text: {
    body: "#EDEDEE", // Body font color
    // ...you might have other text colors
  },
  // ...other colors if necessary
};

// Define typography used in the example application
const typography = {
  fonts: {
    heading: "'Outfit', sans-serif",
    body: "'Open Sans', sans-serif",
  },
  fontWeights: {
    normal: 400,
    medium: 600,
    bold: 700,
  },
  fontSizes: {
    xs: "0.75rem", // 12px
    sm: "0.875rem", // 14px
    md: "1rem", // 16px
    lg: "1.125rem", // 18px
    xl: "1.25rem", // 20px
    "2xl": "1.5rem", // 24px
    "3xl": "1.875rem", // 30px
    "4xl": "2.25rem", // 36px
    "5xl": "3rem", // 48px
    "6xl": "3.75rem", // 60px
  },
};

// Define components styles if necessary
const components = {
  Button: {
    baseStyle: {
      fontWeight: "bold",
    },
    variants: {
      solid: {
        bg: "brand.800",
        color: "white",
        _hover: {
          bg: "brand.700", // Use a lighter blue for hover states
        },
        // ...other states like _active, _focus if necessary
      },
      // ...other variants if necessary
    },
  },
  // ...other component overrides
};

const styles = {
  global: {
    // Global style overrides, for example:
    body: {
      bg: "background.primary",
      color: "text.body",
    },
    // ...any other global styles
  },
};

// Extend the theme
const customTheme = extendTheme({
  colors,
  ...typography,
  components,
  styles,
});

export default customTheme;
