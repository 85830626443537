// src/components/PaymentModal.js
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { redirectToCheckout } from "./PaymentUtils";

const PaymentModal = ({
  isOpen,
  onClose,
  email,
  userId,
  setError,
  setIsLoading,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="gray.600">Payment Required</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color="gray.600">Please complete the payment to access.</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            onClick={() => {
              redirectToCheckout(email, userId, setError, setIsLoading);
              onClose();
            }}
          >
            Proceed to Payment
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PaymentModal;
