import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Divider,
  Heading,
  Text,
} from "@chakra-ui/react";
import React from "react";
import NavigationBar from "./NavigationBar";

const FAQPage = ({ isAuthenticated, handleSignOut }) => {
  return (
    <>
      <NavigationBar
        isAuthenticated={isAuthenticated}
        handleSignOut={handleSignOut}
      />
      <Container maxW={{ base: "90%", md: "85%", lg: "80%" }} py={6}>
        <Heading as="h2" size="xl" textAlign="center" mb={6}>
          Frequently Asked Questions
        </Heading>
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  What is the Cognitive Development Assessment?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              The Cognitive Development Assessment is a tool that uses AI
              technology to evaluate your level of cognitive/ego maturity. It
              helps you understand where you stand in your personal growth
              journey and guides you towards self-improvement.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                How can the assessment help me in my personal and professional
                life?
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              By gaining insights into your ego stage, the assessment can help
              you enhance your relationships, make more informed decisions, and
              improve your overall well-being. It is a step towards
              understanding yourself better and making meaningful changes.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                How long does it take to complete the assessment?
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              The assessment takes about an hour to complete.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Is this a replacement for a psychologist?
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              No, this AI assessment is not a replacement for a psychologist.
              The ego development AI assessment is designed to be a
              supplementary tool that can provide insights and guidance based on
              the responses you provide. While it incorporates sophisticated
              algorithms and psychological principles, it does not equate to or
              replace the personalized expertise and comprehensive evaluation
              provided by a licensed psychologist.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                I am a level 6. Why do I have lower level thoughts/feelings?
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              You are not ever fully "at" a level, it is more like the highest
              level you can think at. We all think at lower levels every day,
              for example, hunger is level 1.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Why is my level so low? Is it bad?
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              A low level could be caused from a number of reasons, including
              poor writing and communication skills. If you are unable to write
              clearly, the AI will not be able to understand your responses and
              will not be able to provide an accurate assessment. Elaborating on
              answers, indicating why you think a certain way, or why others may
              disagree can improve the assessment. It not always better to be at
              a higher level, and people can make their lives worse as they gain
              openness and acceptance of other belief systems. For example, if
              too much thought is at level 6, its easy to fall into the trap of
              being a hippie.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Container>
      <Divider borderColor="#DDD" my={6} />
      <Container my={3} maxW="1000px" fontSize="0.9rem" p={5}>
        <Text color="gray.600">
          When using an AI cognitive development assessment, it's crucial to
          understand both its benefits and limitations. These assessments can
          provide insights into personal growth, moral reasoning, and
          self-awareness. However, they are not comprehensive evaluations of
          one's psychological state. The interpretation of responses can be
          subjective and context-dependent. While AI is useful for organizing
          and analyzing responses, it cannot replace the empathetic judgment and
          nuanced understanding of a human psychologist. AI tools might not
          fully capture the complexities of human emotions and experiences. If
          you are experiencing distress or seeking a thorough understanding of
          your psychological state, it is important to consult a qualified
          psychologist. AI assessments are valuable tools but are not a
          replacement for professional psychological services.
        </Text>
      </Container>
    </>
  );
};

export default FAQPage;
