import { Container, Heading, Link, Text } from "@chakra-ui/react";
import React from "react";
import Footer from "./Footer";
import NavigationBar from "./NavigationBar";

const PrivacyPolicyPage = () => {
  return (
    <>
      <NavigationBar />
      <Container maxW="container.md" py={8}>
        <Heading as="h1" mb={4}>
          Privacy Policy
        </Heading>

        <Text mb={4}>Effective Date: December 18, 2023</Text>

        <Text mb={4}>
          LEVELCHECK.AI respects your privacy and is committed to protecting
          your personal data. This policy outlines our practices regarding data
          collection and usage on our website.
        </Text>

        <Heading as="h2" size="md" mt={6} mb={2}>
          Data Collection
        </Heading>
        <Text mb={4}>
          We collect personal information such as your name and email address
          when you interact with our website, particularly when you register for
          an account. In addition to these details, our service requires you to
          respond to various assessments. These responses are are analyzed by
          our AI algorithms to provide you with personalized psychology
          assessments. The analysis of your responses is designed to understand
          your psychological profile and provide relevant insights. We ensure
          that all data, especially information gathered from these assessments,
          is handled with the utmost confidentiality.
        </Text>

        <Heading as="h2" size="md" mt={6} mb={2}>
          Use of Data
        </Heading>
        <Text mb={4}>
          Your data is used to provide and improve our services, communicate
          with you, and fulfill our legal or regulatory obligations.
        </Text>

        <Heading as="h2" size="md" mt={6} mb={2}>
          Data Sharing
        </Heading>
        <Text mb={4}>
          We do not sell your data. We may share it with service providers who
          assist us in our operations, under confidentiality agreements.
        </Text>

        <Heading as="h2" size="md" mt={6} mb={2}>
          Your Rights
        </Heading>
        <Text mb={4}>
          You have rights regarding your personal data, including access,
          correction, and deletion. Contact us for any requests related to your
          data.
        </Text>

        <Heading as="h2" size="md" mt={6} mb={2}>
          Updates to Privacy Policy
        </Heading>
        <Text mb={4}>
          We may update this policy occasionally. We will notify you of
          significant changes.
        </Text>

        <Text>
          If you have questions about our privacy practices, please email us at{" "}
          <Link href="mailto:support@levelcheck.ai" color="blue.500">
            support@levelcheck.ai
          </Link>
          .
        </Text>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
